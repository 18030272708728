import {Component, Input} from '@angular/core';
import {Location} from "@angular/common";

@Component({
    selector: 'app-error-code',
    templateUrl: './errorCode.component.html',
    styleUrl: './errorCode.component.scss'
})
export class ErrorCodeComponent {

    @Input() errorCode!: number;
    @Input() errorMessage!: string;

  constructor(public location: Location) {}
}
