import {Component} from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrl: './faq.component.scss'
})
export class FaqComponent {

  public faq_data = [
    {
      "active": true,
      "question": "Are these tools free to use?",
      "answer": "<b>Yes, many of our tools are free to use</b>, and we aim to keep them that way. However, " +
        "to cover operational costs, some advanced features may require a subscription or a one-time payment."
    },
    {
      "question": "Do I need to create an account to use these tools?",
      "answer": "<b>No account is required for basic use of the tools.</b> " +
        "However, signing in will allow you to track and manage settings of things like QR codes in the Future."
    },
    {
      "question": "Is my data safe when using these tools?",
      "answer": "Yes, your <b>data is not shared or stored unless necessary</b> for specific functions. " +
        "Some features may require signing in to allow you to save settings for later adjustments. " +
        "Please note that <b>we use Google AdSense for funding</b>, " +
        "which may collect information to display relevant ads. " +
        "You can review <a href='https://policies.google.com/privacy'>Google's privacy policy</a> for more details."
    },
    {
      "question": "What is the QR Generator tool?",
      "answer": "The QR Generator allows you to create QR codes for links, text, " +
        "or contact details that can be scanned by smartphones."
    },
    {
      "question": "How do I use the Image Converter?",
      "answer": "Upload an image, select the desired output format, " +
        "and click 'Convert' to change the image to a different format."
    },
    {
      "question": "What information does the My IP tool provide?",
      "answer": "This tool shows your public IP address and basic device & " +
        "browser information provided by your internet browser."
    }
  ]
}
