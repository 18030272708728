import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-document-area',
    templateUrl: './document-area.component.html',
    styleUrl: './document-area.component.scss'
})
export class DocumentAreaComponent implements OnInit {

    public documentForm!: FormGroup;
    public formSubmitted = false;


    ngOnInit() {
        this.documentForm = new FormGroup({
            name: new FormControl(null, Validators.required),
            email: new FormControl(null, [Validators.required, Validators.email]),
            message: new FormControl(null, Validators.required),
        })
    }

    onSubmit() {
        this.formSubmitted = true;
        if (this.documentForm.valid) {
            console.log('contact-form-value', this.documentForm.value);
            alert(`Message sent successfully`);

            // Reset the form
            this.documentForm.reset();
            this.formSubmitted = false; // Reset formSubmitted to false
        }
        console.log('contact-form', this.documentForm);
    }

    get name() {
        return this.documentForm.get('name')
    }

    get email() {
        return this.documentForm.get('email')
    }

    get message() {
        return this.documentForm.get('message')
    }
}
