import {Component, HostListener, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UtilsService} from '../../../services/utils.service';
import {AuthService} from "../../../services/auth.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {

  constructor(
    public utilsService: UtilsService,
    private router: Router,
    private route: ActivatedRoute,
    public authService: AuthService
  ) {
  }

  ngOnInit(): void {
    this.route.fragment.subscribe({
      next: fragment => {
        if (fragment) {
          this.scrollToFragment(this.router.url.split('#')[0], fragment);
        }
      }
    });
  }

  scrollToFragment(page: string, fragment: string): void {
    this.utilsService.scrollToFragment(page, fragment);
  }

  isFAQRouteActive(): boolean {
    return this.router.url === '/home#faq';
  }

  isToolsRouteActive(): boolean {
    return this.router.url === '/home#tools';
  }

  public sticky: boolean = false;

  // sticky nav
  @HostListener('window:scroll', ['$event']) onscroll() {
    this.sticky = window.scrollY > 100;
  }
}
