<header id="header" class="header-layout1">
  <div id="sticky-header" class="menu-area transparent-header" [class.sticky-menu]="sticky">
    <div class="container custom-container">
      <div class="row">
        <div class="col-12">
          <div class="menu-wrap">
            <nav class="menu-nav justify-content-between">
              <div class="nav-logo">
                <a routerLink="/home">
                  <img src="/assets/img/logo/logo.svg" style="height: 35px;" alt="Logo"> CloudTools
                </a>
              </div>
              <div class="navbar-wrap main-menu d-none d-lg-flex">
                <ul class="navigation">
                  <li [routerLinkActive]="['active']">
                    <a class="section-link pointer" routerLink="/home">Home</a>
                  </li>
                  <li class="menu-item-has-children" [routerLinkActive]="['active']" [class.active]="isToolsRouteActive()">
                    <a class="section-link pointer" (click)="scrollToFragment('/home','tools')">Tools</a>
                    <ul class="sub-menu">
                      <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
                        <a routerLink="/tools/qr-generator">QR Code Generator</a>
                      </li>
                      <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
                        <a routerLink="/tools/image-converter">Image Converter</a>
                      </li>
                      <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
                        <a routerLink="/tools/my-ip">My IP Address</a>
                      </li>
                    </ul>
                  </li>
                  <li [class.active]="isFAQRouteActive()">
                    <a class="section-link pointer" (click)="scrollToFragment('/home','faq')">FAQ</a>
                  </li>
                  <li [routerLinkActive]="['active']">
                    <a routerLink="/contact">Contact</a>
                  </li>
                </ul>
              </div>
              <div class="header-action" *ngIf="!authService.appUser as appUser">
                <ul class="list-wrap">
                  <li class="header-login">
                    <a class="btn2" routerLink="/login">Log in</a>
                  </li>
                </ul>
              </div>
              <div class="header-profile d-none d-lg-flex" *ngIf="authService.appUser as appUser">
                <ul class="navigation">
                  <li class="menu-item-has-children">
                    <a class="section-link pointer d-flex align-items-center">{{ appUser.displayName }}<div class="ms-2 profile-icon">{{ appUser.displayName.charAt(0) }}</div></a>
                    <ul class="sub-menu">
                      <li>
                        <a routerLink="/dashboard/user/profile"><i class="fas fa-user"></i> Profile</a>
                      </li>
                      <li>
                        <a routerLink="/dashboard"><i class="fas fa-tools"></i> Dashboard</a>
                      </li>
                      <li>
                        <a routerLink="/dashboard/user/billing"><i class="far fa-credit-card"></i> Billing</a>
                      </li>
                      <li>
                        <a (click)="authService.signOut()"><i class="fas fa-sign-out-alt"></i> Log out</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div (click)="utilsService.openMobileMenus = !utilsService.openMobileMenus"
                   class="mobile-nav-toggler d-flex d-lg-none">
                <i class="fas fa-bars"></i>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- mobile offcanvas menu -->
  <app-mobile-offcanvas [menuTwo]="true"/>
  <!-- mobile offcanvas menu -->
</header>
