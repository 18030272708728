<div class="brand-area3 pb-100">
  <div class="container">
      <div class="row g-0">
          <div class="col-lg-12">
              <div class="brand-title2 text-center">
                  <h6 class="title">Supported by Our Top Donors</h6>
              </div>
          </div>
      </div>
      <div class="brand-item-wrap3">
          <div class="row g-0 brand-active2 swiper">
            <div class="swiper-wrapper p-0">
              <div *ngFor="let b of brands" class="col-12 swiper-slide">
                  <div class="brand-item">
                    <img [src]="b" alt="brand-logo">
                  </div>
              </div>
            </div>
          </div>
      </div>
  </div>
</div>
