import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {UtilsService} from '../../services/utils.service';

@Component({
    selector: 'app-mobile-offcanvas',
    templateUrl: './mobile-offcanvas.component.html',
    styleUrl: './mobile-offcanvas.component.scss'
})
export class MobileOffcanvasComponent {

    @Input() menuTwo: boolean = false;
    public openSubMenu: string = '';

    constructor(public utilsService: UtilsService, private router: Router) {
    }

    scrollToFragment(fragment: string): void {
        setTimeout(() => {
            const element = document.getElementById(fragment);
            if (element) {
                const offsetTop = element.offsetTop - 60;
                console.log('element offset', element, offsetTop);
                window.scrollTo({top: offsetTop, behavior: 'smooth'});
            }
        }, 100);
    }

    navigateWithOffset(url: string, fragment: string): void {
        this.router.navigate([url], {fragment: fragment});
        this.scrollToFragment(fragment);
        this.utilsService.openMobileMenus = !this.utilsService.openMobileMenus;
    }

    handleSubMenu = (title: string) => {
        if (this.openSubMenu === title) {
            this.openSubMenu = '';
        } else {
            this.openSubMenu = title;
        }
    };
}
