<section class="breadcrumb-area breadcrumb-bg" style="background-image: url(/assets/img/bg/breadcrumb_bg.png);">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="breadcrumb-content">
          <p class="fs-1">{{ errorMessage }}</p>
          <h2 class="title pb-10">Error <span [countUp]="errorCode" [duration]="2000"></span></h2>
          <button class="btn btn-primary" (click)="location.back()">Go Back</button>
        </div>
      </div>
    </div>
  </div>
  <div class="breadcrumb-shape-wrap">
    <img src="/assets/img/images/breadcrumb_shape02.png" alt="" class="alltuchtopdown">
    <img src="/assets/img/images/breadcrumb_shape02.png" alt="" class="rotateme">
  </div>
</section>
