import {Component, OnInit} from '@angular/core';
import Swiper from 'swiper';
import {Pagination} from 'swiper/modules';

@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html',
  styleUrl: './tools.component.scss'
})
export class ToolsComponent implements OnInit {

  public partner_data = [
    {
      img: '/assets/img/tools/qr.png',
      title: 'QR Code</br> Generator',
      text: 'Create custom QR codes for links, text, or contact details quickly and easily.',
      link: '/tools/qr-generator'
    },
    {
      img: '/assets/img/tools/convert.png',
      title: 'Image</br> Converter',
      text: 'Convert images between various formats in just a few clicks.',
      link: '/tools/image-converter'
    },
    {
      img: '/assets/img/tools/internet.png',
      title: 'IP Address </br> Device Info',
      text: 'Check your IP address and get details about your device and browser.',
      link: '/tools/my-ip'
    }
  ];

  ngOnInit() {
    new Swiper('.partner-slider1', {
      slidesPerView: 3,
      spaceBetween: 30,
      loop: false,
      pagination: {
        clickable: true,
        el: '.ns-swiper-dot-1',
      },
      modules: [Pagination],
      breakpoints: {
        '1200': {
          slidesPerView: 3,
        },
        '992': {
          slidesPerView: 3,
        },
        '768': {
          slidesPerView: 2,
        },
        '576': {
          slidesPerView: 1,
        },
        '0': {
          slidesPerView: 1,
        },
      },
    });
  }
}
