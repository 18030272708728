import {Component, OnInit} from '@angular/core';
import Swiper from 'swiper';

@Component({
    selector: 'app-partners',
    templateUrl: './partners.component.html',
    styleUrl: './partners.component.scss'
})
export class PartnersComponent implements OnInit {

    public brands = [
        '/assets/img/update/client/client-2-1.svg',
        '/assets/img/update/client/client-2-2.svg',
        '/assets/img/update/client/client-2-3.svg',
        '/assets/img/update/client/client-2-4.svg',
        '/assets/img/update/client/client-2-5.svg',
        '/assets/img/update/client/client-2-1.svg',
        '/assets/img/update/client/client-2-2.svg',
        '/assets/img/update/client/client-2-3.svg',
    ];

    ngOnInit() {
        new Swiper('.brand-active2', {
            slidesPerView: 5,
            spaceBetween: 0,
            loop: false,
            breakpoints: {
                '1200': {
                    slidesPerView: 5,
                },
                '992': {
                    slidesPerView: 4,
                },
                '768': {
                    slidesPerView: 3,
                },
                '576': {
                    slidesPerView: 2,
                },
                '0': {
                    slidesPerView: 2,
                },
            },
        });
    }
}
